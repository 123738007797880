import React, { useEffect, useRef, useState } from 'react'

import { skills } from '../../API/SkillsData'

import TrackVisibility from "react-on-screen";


import './Skills.css'
import Progress from './Progress';

function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [])

    return isIntersecting
}

const Skills = () => {
    const ref = useRef()
    const SkillsAreVisible = useOnScreen(ref)

    return (
        <>
            <div ref={ref} className="mi-skills-area mi-section mi-padding-top" >
                <div className="container" >


                    <div className="mi-skills" data-aos="zoom-in">
                        <div className="row mt-30-reverse">
                            {skills.map((Types, id) => (
                                <div key={id} className="mi_skills_section"  >
                                    <h3 class="title title--h3 skills_title"> {Types.skillType}</h3>
                                    <div className="row mt-30-reverse">
                                        {Types.skills.map((skill, id) => (

                                            <TrackVisibility
                                                once
                                                className="col-lg-4 mt-30"
                                                key={skill.title}
                                            >
                                                <Progress title={skill.title} percentage={skill.percentage} SkillsAreVisible={SkillsAreVisible} />
                                            </TrackVisibility>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Skills