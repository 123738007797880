import React, { useState, useEffect } from "react";
import profileImage from "../../../assets/images/RamezBenAribia.jpg";

import { BsCalendarEvent } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { GrLocation } from "react-icons/gr";
import { AiOutlinePhone } from "react-icons/ai";

import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-blue.css";

import Resume from "../../../assets/pdf/Resume.pdf";

import "./SideBar.css";
import DownloadButton from "./DownloadButton/DownloadButton";

const SideBar = () => {
  const [isVisible, setIsVisible] = useState(false);

  const listenToScroll = () => {
    let heightToHideFrom = 100;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);
  const active = isVisible ? "is-fixed" : "";

  return (
    <>
      <aside class="col-12 col-md-12 col-xl-3">
        <div class={`sidebar box shadow pb-0 sticky-column  ${active}`}>
          <div class="db_face_images">
            <div class="circle-ripple"></div>
            <img src={profileImage} alt="" class="img-fluid" />
          </div>

          <div class="text-center">
            <h3 class="title title--h3 sidebar__user-name">
              <span class="weight--500">Ramez Ben Aribia</span>
            </h3>
            <div class="badge badge--light">DevOps Engineer </div>

            <ul class="social">
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/ramez.benaribia.54"
                >
                  <i class="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://twitter.com/ramez_ben"
                >
                  <i class="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/in/ramez-ben-aribia/"
                >
                  <i class="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                {" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://github.com/ramezbenaribia"
                >
                  <i class="fa fa-github"></i>
                </a>
              </li>
            </ul>
          </div>

          <div class="sidebar__info box-inner box-inner--rounded">
            <ul class="contacts-block">
              <li
                class="contacts-block__item"
                data-toggle="tooltip"
                data-placement="top"
                title="Birthday"
              >
                <BsCalendarEvent className="icon-sideBar" /> December 22, 1999 (
                {new Date().getFullYear() - 1999} Years)
              </li>
              <li
                class="contacts-block__item"
                data-toggle="tooltip"
                data-placement="top"
                title="Address"
              >
                <GrLocation className="icon-sideBar" />
                Berlin, Germany
              </li>
              <li
                class="contacts-block__item"
                data-toggle="tooltip"
                data-placement="top"
                title="E-mail"
              >
                <a href={`mailto:ramezbenaribia@gmail.com`}>
                  <HiOutlineMail className="icon-sideBar" />
                  ramezbenaribia@gmail.com
                </a>
              </li>
              <li
                class="contacts-block__item"
                data-toggle="tooltip"
                data-placement="top"
                title="Phone"
              >
                <AiOutlinePhone className="icon-sideBar" />
                <a href={`tel:+49 176 27467911`}>+49 176 27467911</a>
              </li>
            </ul>
            {/* <AwesomeButton
                            type="primary"
                            href={Resume}
                            target="_blank"
                            className="aws-btn "
                        >
                            Download Resume
                        </AwesomeButton> */}

            <DownloadButton className="aws-btn" />
          </div>
        </div>
      </aside>
    </>
  );
};

export default SideBar;
