import React from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";

import Main from './components/Layout/Main/Main';
import SideBar from './components/Layout/SideBar/SideBar';

import { BrowserRouter as Router } from "react-router-dom";

function App() {

  return (

    <>
      {/* <div class="preloader">
        <div class="preloader__wrap">
          <div class="circle-pulse">
            <div class="circle-pulse__1"></div>
            <div class="circle-pulse__2"></div>
          </div>
          <div class="preloader__progress"><span></span></div>
        </div>
      </div> */}

      <Router>
        <main class="main">
          <div class="container gutter-top">
            <div class="row sticky-parent">

              <SideBar />



              <Main />

            </div>
          </div>
        </main>


      </Router>



    </>
  );
}

export default App;
