import React, { useEffect, useRef, useState } from 'react'

import { competences } from '../../API/CompetencesData'



import './Competence.css'
import ProgressCircle from './ProgressCircle'

function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [])

    return isIntersecting
}

const Competence = () => {

    const ref = useRef()
    const isVisible = useOnScreen(ref)

    return (
        <>
            <div ref={ref} className="mi-skills-area mi-section mi-padding-top" >
                <div className="container" >


                    <div className="mi-skills" data-aos="zoom-in">
                        <div className="row mt-30-reverse">
                            {competences.map((competence, index) => (
                                <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-mt-6 mi-competence-item">
                                    <ProgressCircle isVisible={isVisible} progress={competence.percentage} />
                                    <h5>{competence.title}</h5>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Competence