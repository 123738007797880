import React from 'react'
import Resume from '../../../../assets/pdf/Resume.pdf'

import './DownloadButton.css'

const DownloadButton = () => {
    return (
        <>
            <a href={Resume} rel="noreferrer" target="_blank">
                <button  >

                    Download Resume
                    <span></span><span></span><span></span><span></span>
                </button>
            </a>

        </>
    )
}

export default DownloadButton