import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";


import { FaRegUser, FaRegEnvelope, FaLocationArrow } from 'react-icons/fa';
import { MdOutlineSubject } from 'react-icons/md';


import './Contact.css'

const Contact = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const form = useRef();

    const subimtHandler = (e) => {
        e.preventDefault();

        // set them to empty values
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");


        const serviceId = 'service_ng5r8se'
        const templateId = 'ramezbenAribiaContact';
        const userID = 'user_xjnqPj6Tfa1nXVbz8ap2h';

        emailjs
            .sendForm(
                serviceId,
                templateId,
                form.current,
                userID
            )
            .then(
                (result) => {
                    console.log("Email sent");
                    // console.log(result.text);
                },
                (error) => {
                    console.log("error.text");
                    console.log(error.text);
                }
            );
    };


    return (

        <>

            <h1 class="title title--h1 first-title title__separate">Contact </h1>




            <form ref={form} onSubmit={subimtHandler} id="contact-form" class="contact-form" data-toggle="validator" novalidate="true">

                <div class="row">
                    <div class="form-group col-12 col-md-6">
                        <FaRegUser className="font-icon icon-user" />
                        <input value={name} onChange={(e) => setName(e.target.value)}
                            type="text" class="input input__icon form-control" id="nameContact" name="nameContact" placeholder="Full name" required="required" autocomplete="on" oninvalid="setCustomValidity('Fill in the field')" onkeyup="setCustomValidity('')" />
                        <div class="help-block with-errors"></div>
                    </div>
                    <div class="form-group col-12 col-md-6">
                        <FaRegEnvelope className='font-icon icon-envelope' />
                        <input onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            type="email"
                            class="input input__icon form-control"
                            id="emailContact"
                            name="emailContact"
                            placeholder="Email address" required="required" autocomplete="on" oninvalid="setCustomValidity('Email is incorrect')" onkeyup="setCustomValidity('')" />
                        <div class="help-block with-errors"></div>
                    </div>

                    <div class="form-group col-12 col-md-12">
                        <MdOutlineSubject className='font-icon icon-envelope' />
                        <input
                            onChange={(e) => setSubject(e.target.value)}
                            value={subject}
                            type="subject"
                            class="input input__icon form-control"
                            id="subjectContact"
                            name="subjectContact"
                            placeholder="Subject" required="required"
                            autocomplete="on"
                            oninvalid="setCustomValidity('Email is incorrect')"
                            onkeyup="setCustomValidity('')" />
                        <div class="help-block with-errors"></div>
                    </div>

                    <div class="form-group col-12 col-md-12">
                        <textarea onChange={(e) => setMessage(e.target.value)}
                            value={message}
                            class="textarea form-control" id="messageContact" name="messageContact" placeholder="Your Message" rows="4" required="required" oninvalid="setCustomValidity('Fill in the field')" onkeyup="setCustomValidity('')" style={{ overflow: "hidden", height: "118px", outline: "none" }} tabindex="1"></textarea>
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 order-2 order-md-1 text-center text-md-left">
                        <div id="validator-contact" class="hidden"></div>
                    </div>
                    <div class="col-12 col-md-6 order-1 order-md-2 text-right">
                        <button type="submit" className="contact_btn ">
                            <FaLocationArrow className='font-icon icon-send' />
                            Send Message
                        </button>
                    </div>
                </div>
            </form>



        </>
    )
}

export default Contact