import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import syphemco from '../../../../assets/images/Clients/syphemco.png'
import LaCasaDePadel from '../../../../assets/images/Clients/LaCasaDePadel.png'
import Devpack from '../../../../assets/images/Clients/Devpack.jpg'
import semat from '../../../../assets/images/Clients/semat.png'


const content = [
    {
        image: syphemco,
        Name: 'SYPHEMCO'
    },
    {
        image: Devpack,
        Name: 'DevPack'
    },
    {
        image: semat,
        Name: 'Semat'
    },
    {
        image: LaCasaDePadel,
        Name: 'La Casa Del Padel'
    },


]


class Clients extends Component {

    render() {


        function responsive(slidesToShow1 = 3, slidesToShow2 = 2, slidesToShow3 = 1) {
            return [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: slidesToShow1,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: slidesToShow2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: slidesToShow3,
                        slidesToScroll: 1
                    }
                }
            ]
        }


        const settings = {
            dots: true,
            infinite: true,
            speed: 10000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1000,
            responsive: responsive()

        };
        return (
            <>
                <Slider {...settings} className="clients-two-dotsl dots-long d-primary btn-style-1">
                    {content.map((item, id) => (
                        <div class="swiper-slide client-div review-item swiper-slide-active" >

                            <div >
                                <a href="#"><img src={item.image} alt="Logo" /></a>
                            </div>
                            {/* <h3 className="title title--h5">{item.Name}</h3> */}

                        </div>
                    ))}
                </Slider>

            </>
        )
    }
}
export default Clients;