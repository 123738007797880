import React from "react";
import styled from "styled-components";

const DisplayOver = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  transition: background-color 350ms ease;
  background-color: transparent;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
`;

const BigTitle = styled.h6`
  background: #03201685;
  border-radius: 15px 0;
  text-align: center;
  font-family: Helvetica;
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 25%;
  padding: 10px ;
  display: flex;
    align-items: center;
    justify-content: center;
    color: #f7f7f7;
`;

const Hover = styled.div`
  opacity: 0;
  transition: opacity 350ms ease;
`;

const SubTitle = styled.h4`
  font-family: Helvetica;
  transform: translate3d(0, 50px, 0);
  transition: transform 350ms ease;
`;

const Paragraph = styled.p`
  transform: translate3d(0, 50px, 0);
  transition: transform 350ms ease;
  font-size: 1.1rem !important;
    font-weight: bold;
    color: #1e7256 !important;
`;

const Background = styled.a`
  background-size: cover;
  background-repeat: no-repeat;
  color: "#FFF";
  position: relative;
  cursor: pointer;
  margin-top: 15px;
  height: 100% ;

  :hover img {
    -webkit-filter: grayscale(100%) blur(4px);
    filter: grayscale(100%) blur(4px);
  }

  :hover ${DisplayOver} {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 0px;
  }
  :hover ${SubTitle}, :hover ${Paragraph} {
    transform: translate3d(0, 0, 0);
  }
  :hover ${Hover} {
    opacity: 1;
  }
`;

const Card = (props) => {
  return (
    <>
      <Background
        href={props.filesource}
        target="_blank"
        className="mi-resume-certificate"
      >
        <img src={props.image} className="Card_Image" alt="lol" />
        <DisplayOver>
          <BigTitle className="mi-res-certif-tittle">{props.title}</BigTitle>
          <Hover>
            <Paragraph id="mi-resmue-certificate-p">{props.paragraph}</Paragraph>
          </Hover>
        </DisplayOver>
      </Background>

    </>
  );
};

export default Card;
