import React, { useState } from 'react'
import { NavLink } from "react-router-dom";

import './Header.css'

const Header = () => {
    const [isOpen, setOpen] = useState(true);
    return (
        <>
            <div class="circle-menu">
                <button
                    onClick={() => setOpen(!isOpen)}
                    className={`hamburger-button ${isOpen ? "open" : "close"}`}
                />



            </div>

            <div className={`inner-menu js-menu  ${isOpen ? "open" : "close"}`}>
                <ul class="nav">
                    <li class="nav__item"><NavLink class="active" to="/">About</NavLink></li>
                    <li class="nav__item"><NavLink to="resume">Resume</NavLink></li>
                    <li class="nav__item"><NavLink to="portfolios">Portfolio</NavLink></li>
                    <li class="nav__item"><NavLink to="contact">Contact</NavLink></li>
                </ul>
            </div>

        </>
    )
}

export default Header