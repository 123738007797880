import React from "react";



import { Route, Routes } from "react-router-dom";


import "./Main.css";
import Resume from "../../Pages/Resume/Resume";
import About from "../../Pages/About/About";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Portfolios from "../../Pages/Portfolios/Portfolios";
import Contact from "../../Pages/Contact/Contact";
import PortfolioDetails from "../../Pages/PortfolioDetails/PortfolioDetails";
import ScrollToTop from "../../Shared/ScrollToTop";


const Main = () => {
    return (
        <>


            <div class="col-12 col-md-12 col-xl-9">
                <div class="box shadow pb-0 main_box">


                    <Header />


                    <Routes>
                        <Route exact path="/" element={<About />} />
                        <Route exact path="resume" element={<Resume />} />
                        <Route exact path="portfolios" element={<Portfolios />} />
                        <Route exact path="contact" element={<Contact />} />
                        <Route
                            exact
                            path="/portfolios/details/:portfolioId"
                            element={<PortfolioDetails />}
                        />
                    </Routes>

                </div>
                <ScrollToTop />
                <Footer />
            </div>



        </>
    )


}

export default Main;