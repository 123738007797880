export const competences = [
  {
    title: " • Creativity",
    percentage: "90",
  },
  { title: " • Verbal Communication", percentage: "90" },
  {
    title: " • Leadership",
    percentage: "75",
  },
  { title: " • Team Working ", percentage: "91" },

  { title: " • Time Management", percentage: "88" },
  {
    title: " • Problem Solving ",
    percentage: "87",
  },
  { title: " • Resiliency  ", percentage: "86" },
  {
    title: " • Critical thinking",
    percentage: "84",
  },
];
