import React from "react";

function Progress(props) {
    const [progress, setProgress] = React.useState(0);
    React.useEffect(() => {
        if (props.SkillsAreVisible) {

            if (props.percentage > progress) {
                setTimeout(() => {
                    setProgress(progress + 1);
                }, 5);

            }
        }


    }, [progress, props.percentage, props.SkillsAreVisible]);

    const progressQuery = () => {
        return (
            <span
                className="mi-progress-active"
                style={{ width: `${progress}%` }}
            ></span>
        );
    };
    return (
        <div className="mi-progress">
            <div className="mi-progress-inner">
                <h6 className="mi-progress-title">{props.title}</h6>
                <div className="mi-progress-percentage">{`${progress}%`}</div>
            </div>
            <div className="mi-progress-container">{progressQuery()}</div>
        </div>
    );
}

export default Progress;
