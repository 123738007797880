export const Working_Experience = [
  {
    time: "02/2023  -  Present",
    state: "DevOps Engineer - Internship",
    place: "Swobbee Gmbh, Berlin, Germany",
    description:
      " •  Implement an automated invoice creation system by utilizing a Lambda function, SQS, and a cron job.",
    description2: ` • Develop and execute integration tests for the workflow, ensuring seamless integration,
    and employ Jenkins to deploy the final solution.`,
    description3: ` • Establish synchronization between the Odoo user pool and the Cognito user pool,
    enabling Odoo to function as an OpenID provider for Cognito and vice versa.`,
    description4: ` • Facilitate the transition from Odoo Online to Odoo.sh, implementing a streamlined synchronization workflow aimed at automating the development and testing phases of modules.`,
  },
  {
    time: "07/2022  -  10/2022",
    state: "DevOps Engineer - Internship",
    place: "IPOMM By DATA PM, Tunis, Tunisia",
    description:
      " • Automate Odoo deployment and scalability on kubernetes cluster.",
    description2: " • Built an operator that manages CRDs for Odoo deployment.",
    description3: ` • Slashed deployment time 90% by reducing manual interaction with kuberntes cluster,
      while ensuring the continuation and enhancements of services.`,
    description4:
      " • Documented the Project so it can be understandable and reusable for the company.",
  },
  {
    time: "03/2021  -  05/2022",
    state: "Cloud Engineer - Part-time",
    place: "bc-competence gmbh, Germany, (Remote)",
    description:
      " • Managed to implement an RPA(Robotic Process Automation) and Document Processing Solution using an Angular, AWS Lambda and DynamoDB.",
    description2:
      " • Followed a serverless architecture using mainly AWS Lambda, DynamoDB, AWS Secrets and CloudFormation among other AWS Services.",
    description3:
      " • Implemented Unit and Integration Tests and documented new developed features.",
  },
  {
    time: "07/2021  -  10/2021",
    state: "Full Stack Developer - Internship",
    place: "Mantu (Amaris Consulting), Tunis, Tunisia",
    description:
      " • Mantu is an international group providing guidance and services to businesses and    entrepreneurs.",
    description2:
      " • Worked on a profile/Job matching web-based service with VueJs, .Net and ElasticSearch.",
    description3:
      " • The service aims to find the best match for the job among the companies list of consultants/candidates.",
    description4: " • Worked on Elasticsearch Cluster Management.",
  },
];

export const Educational_Qualifications = [
  {
    time: "09/2018 - 09/2023",
    state: "Software Engineering Diploma",
    place:
      "National Institute of Applied Sciences Technology (INSAT), Tunis, Tunisia",
    description:
      " • INSAT is one of the best engineering schools in Tunisia, with a 5 years cursus equivalent to a Bachelor and a Master Degree.",
    description2: ` • Relevant Coursework: Linear Algebra, Object-Oriented Programming, 
        Algorithms, Logic, Theory of Language, Software Architecture, Design Pattern, 
        Artificial Intelligence, Full-stack Web Development, Database Administration, DevOps,
        Networks Management.`,
  },
  {
    time: "09/2014 - 06/2018",
    state: "Baccalaureate Diploma",
    place: "Hedi Soussi High School, Sfax, Tunisia",
    description:
      " • Baccalaureate in Mathematics obtained with highest honor and as major of promotion in Hedi Soussi High school.",
    description2: " • Score: 15.68/20.",
  },
];

export const Other_achievements = [
  {
    time: "2020 - 2021",
    state: "IEEE INSAT",
    place: "Treasurer and Sponsoring Manager of CS chapter",
    description: ` • Assuming a role within a club entails assuming a significant degree of responsibility for fellow members, particularly when occupying positions such as Treasurer or Sponsoring Manager. This experience has profoundly transformed my perspective, imbuing me with insights into the art of persuading companies to consider sponsorship opportunities. Serving as a Treasurer has also exposed me to the intricacies of financial management, presenting a multifaceted challenge that I actively embraced and navigated. `,
    description2: `• In my dual role as Treasurer and Sponsorship Officer, I had the privilege of organizing a major event, the Computer Society Tech Congress. This multi-faceted undertaking included the coordination and seamless oversight of over 200 attendees who comprised a diverse and dynamic group of stakeholders. My essential role went beyond financial management to include active participation in the overall event management process. This invaluable experience demonstrated my ability to navigate complex logistics and foster a collaborative environment, which reinforced my commitment to careful planning and efficient execution.`,
  },

  {
    time: "2018 - 2021",
    state: "IEEE INSAT",
    place: "Member",
    description: ` • Taking on an active role within the organization presented me with a major challenge,
     and made me aware of the paramount importance of making a meticulous effort and demonstrating my skills and abilities. This experience made me aware of the essential link between commitment and the successful presentation of my skills,
     and encouraged me to always strive for excellence and make a valuable contribution to the organization's efforts.`,
  },
  {
    time: "2019-2021",
    state: "Theater club INSAT ",
    place: " Member ",
    description:
      " • My love for theater made me a committed and dedicated club member, which had a transformative impact on my communication and public speaking skills. This commitment not only reinforced my love for the performing arts, but also served as a dynamic platform to hone my expressive skills and the art of effective public speaking.",
    description2:
      " • Being involved in the world of theater, stage and speaking in front of audiences of over 500 people has greatly strengthened my resilience and given me invaluable stress management skills. Exposure to these large-scale public interactions was a crucible for cultivating an unshakeable calm in the face of pressure, and gave me the ability to skillfully navigate difficult situations with equanimity.",
  },
  {
    time: "2015-2017",
    state: "Mohamed Jamoussi Club for Theater, Sfax, Tunisia",
    place: "Member",
    description: ` • My passion for comedy turned into a veritable obsession, which led me to take an active part in the Club Mohamed Jamoussi pour le théâtre. This fervor bore fruit, as I had the honor of winning two major prizes in a national competition, including the one for best play. This remarkable result testifies to my commitment and skills in the field of theater, which continue to fuel my passion and drive for artistic excellence.. `,
  },
];
