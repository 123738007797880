import React from 'react'
import Certificates from './Components/Certificates/Certificates'
import Competence from './Components/Competence/Competence'
import History from './Components/History/History'
import Skills from './Components/Skills/Skills'

import './Resume.css'


const Resume = () => {
    return (
        <>

            <div class="pb-3">
                <h1 class="title title--h1 first-title title__separate">Resume</h1>
                <History />
            </div>


            <div class="pb-3 box-inner box_inner__grey">
                <h1 class="title title--h1 first-title title__separate"> Technical  Skills</h1>
                <Skills />
            </div>


            <div class="pb-3">
                <h1 class="title title--h1 first-title title__separate">Soft  Skills</h1>
                <Competence />
            </div>

            <div class="pb-3 box-inner box_inner__grey">
                <h1 class="title title--h1 first-title title__separate">Certificates</h1>
                <Certificates />
            </div>






        </>
    )
}

export default Resume