import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'


import { portfolios } from './API/PortfolioData'

import './Portfolios.css'



const Portfolios = () => {



    const filterImages = (filterKey, images) => {
        const list = images.filter(image =>
            filterKey === 'All' ? image : image.type === filterKey
        );

        return list
    }

    const getFilterSections = (images) => {
        const filters = ['All']
        images.map(image => {
            if (filters.indexOf(image.type) === -1) {
                filters.push(image.type)
            }
            return 0
        })
        return filters
    }
    const [state, setState] = useState({
        list: portfolios,
        filterKey: "All"
    });

    const FilterFunction = (filter, id) => {
        setState({ ...state, filterKey: filter })
        let filterButtons = document.getElementsByClassName("filter_btn")
        for (var i = 0; i < filterButtons.length; i++) {
            filterButtons[i].classList.remove('active');
        }
        document.getElementById(id).classList.add('active');
    }


    const { list, filterKey } = state;

    const filteredList = filterImages(filterKey, list)
    const filters = getFilterSections(portfolios)

    useEffect(() => {
        document.getElementById(0).classList.add('active');
    }, []);


    return (
        <>

            <div class="pb-3">
                <h1 class="title title--h1 first-title title__separate">Portfolio </h1>


                <div className="content-block">
                    <div className="section-full content-inner-2 portfolio text-uppercase bg-gray" id="portfolio">
                        <div className="container">
                            <div className="site-filters clearfix center  m-b40">

                                {/* FILTERS */}
                                <ul className="filters" data-toggle="buttons">
                                    {filters.map((filter, id) => (
                                        <li id={id} key={id} className="btn filter_btn " onClick={() => FilterFunction(filter, id)}>
                                            <div className="site-button-secondry button-sm radius-xl">
                                                <span>{filter}</span>
                                            </div>
                                        </li >

                                    ))}
                                </ul>
                            </div>

                            <div className="button-container">



                                {/* FILTERED PORTFOLIO LIST */}
                                <div className="portfolio_area">
                                    <div className="row portfolio-grid">
                                        {filteredList.map((image, id) => (
                                            <div key={id} className="col-12 col-sm-6 portfolio_card" data-aos="zoom-in" >
                                                <div className={`dlab-box dlab-gallery-box fade-in-image `}>
                                                    <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                                                        <Link to={`/portfolios/details/${image.id}`}>
                                                            <img className='dlab_box_image' src={image.featuredImage} alt="Ramez Ben Aribia" />
                                                        </Link>
                                                    </div>
                                                    <div className="dez-info p-a30  bg-card portfolio_card_title ">
                                                        <span>
                                                            {image.title}
                                                        </span>
                                                        {/* <p className="dez-title m-t0"><Link to="/portfolio-details">{image.title}</Link></p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )).reverse()}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>





            </div>

        </>
    )
}

export default Portfolios