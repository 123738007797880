import React from "react";

import {
  Working_Experience,
  Educational_Qualifications,
  Other_achievements,
} from "../../API/ResumeData";

import "./History.css";

import EducationIcon from "../../../../../assets/icons/mortarboard.png";
import ExperienceIcon from "../../../../../assets/icons/suitcase.png";
import SoftSkillsIcon from "../../../../../assets/icons/soft_skills.png";

const History = () => {
  return (
    <>
      <div class="pb-0">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h2 class="title title--h3">
              <img class="title-icon" src={ExperienceIcon} alt="" /> Experience
            </h2>
            <div class="timeline">
              {Working_Experience.map((item, id) => (
                <article key={id} class="timeline__item">
                  <div className="resume-period">
                    <h5 class="title title--h5 timeline__title">
                      {item.state}
                    </h5>
                    <span class="timeline__period">{item.time}</span>
                    <p class="timeline__place">{item.place}</p>
                    <p class="timeline__description">{item.description}</p>
                    <p class="timeline__description">{item.description2}</p>
                    <p class="timeline__description">{item.description3}</p>
                    <p class="timeline__description">{item.description4}</p>
                  </div>
                </article>
              ))}
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <h3 class="title title--h3">
              <img class="title-icon" src={EducationIcon} alt="" /> Education
            </h3>
            <div class="timeline">
              {Educational_Qualifications.map((item, id) => (
                <article key={id} class="timeline__item">
                  <div className="resume-period">
                    <h5 class="title title--h5 timeline__title">
                      {item.state}
                    </h5>
                    <span class="timeline__period">{item.time}</span>
                    <p class="timeline__place">{item.place}</p>
                    <p class="timeline__description">{item.description}</p>
                    <p class="timeline__description">{item.description2}</p>
                  </div>
                </article>
              ))}
            </div>
          </div>
        </div>
        <div className="row soft_skills">
          <div class="col-12 col-lg-12">
            <h2 class="title title--h3">
              <img class="title-icon" src={SoftSkillsIcon} alt="" />{" "}
              Extracurricular Activities
            </h2>
            <div class="timeline">
              {Other_achievements.map((item, id) => (
                <article key={id} class="timeline__item">
                  <div className="resume-period">
                    <h5 class="title title--h5 timeline__title">
                      {item.state}
                    </h5>
                    <span class="timeline__period">{item.time}</span>
                    <p class="timeline__place">{item.place}</p>
                    <p class="timeline__description">{item.description}</p>
                    <p class="timeline__description">{item.description2}</p>
                  </div>
                </article>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
