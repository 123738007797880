import React from "react";
import { Carousel } from "react-responsive-carousel";

import { HiExternalLink } from "react-icons/hi";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./SliderImage.scss";

const SliderImage = ({ images, url }) => {
  return (
    <>
      <Carousel
        height="500px"
        autoPlay={false}
        infiniteLoop={true}
        interval={3000}
      >
        {images &&
          images.map((item, index) => (
            <div
              key={item}
              style={{ height: "100%" }}
              className="wpo-project-img"
            >
              <img
                style={{ objectFit: "contain", height: "100%" }}
                src={item}
                alt=""
              />
              <div className="right-border"></div>
              <div className="left-border"></div>
              {url && (
                <>
                  <div className="wpo-project-text category-title">
                    <h2>
                      <a rel="noopener noreferrer" target="_blank" href={url}>
                        visit project
                        <HiExternalLink />
                      </a>
                    </h2>
                  </div>
                </>
              )}
            </div>
          ))}
      </Carousel>
    </>
  );
};

export default SliderImage;
