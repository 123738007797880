import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <>
            <footer class="footer">©  {new Date().getFullYear()} Ramez Ben Aribia</footer>

        </>
    )
}

export default Footer