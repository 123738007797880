import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import FeridChabbi from '../../../../assets/images/Testimonials/FeridChabbi.jpg'
import SouhaBenHassine from '../../../../assets/images/Testimonials/SouhaBenHassine.jpg'

import { ImQuotesLeft } from 'react-icons/im';

const content = [
    {
        image: FeridChabbi,
        author: 'Ferid Chabbi',
        designation: 'R&D Engineering Manager',
        description:
            `I had the pleasure to mentor Ramez during his internship at Mantu and
         I was stunned by his motivation and his eagerness to learn.
         He demonstrated great skills, both technical and organizational. By the end of his internship, 
         he delivered a high quality piece of software that proves his readiness to start his professional journey.
        I'm confident that he will achieve great things in the future and I'll be honored to work with him again!`
    },
    {
        image: SouhaBenHassine,
        author: 'Souha Ben Hassine',
        designation: 'Software Engineering Student at INSAT',
        description:
            `I had the privilege to know and work with Ramez on many occasions, 
        since he was the treasurer of IEEE CS INSAT SBC, and my colleague in Mantu and college.
        Ramez is a hardworking, result-oriented, responsible,
         and multi-skilled engineer and he is always ready to put all his energy and time to get the job done. 
        Self-motivated, ambitious, energetic, and proactive perfectionist, 
        Ramez is always a massive contributor to our projects. He consistently delivers high-quality software products, 
        meets deadlines, and assists his co-workers at every opportunity.
        Desire for proficiency and knowledge makes Ramez a valuable asset to any company.,`

    }

]


class Testmonial extends Component {

    render() {


        function responsive(slidesToShow1 = 3, slidesToShow2 = 2, slidesToShow3 = 1) {
            return [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: slidesToShow1,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: slidesToShow2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: slidesToShow3,
                        slidesToScroll: 1
                    }
                }
            ]
        }


        const settings = {
            dots: true,
            infinite: true,
            speed: 3500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 9000,
            responsive: responsive()

        };
        return (
            <>
                <Slider {...settings} className="testimonial-two-dotsl dots-long d-primary btn-style-1">
                    {content.map((item, id) => (
                        <div class="swiper-slide review-item swiper-slide-active" >
                            <ImQuotesLeft className='quotes' />

                            <svg class="svg-defs">
                                <clipPath id="avatar-box">
                                    <path d="M1.85379 38.4859C2.9221 18.6653 18.6653 2.92275 38.4858 1.85453 56.0986.905299 77.2792 0 94 0c16.721 0 37.901.905299 55.514 1.85453 19.821 1.06822 35.564 16.81077 36.632 36.63137C187.095 56.0922 188 77.267 188 94c0 16.733-.905 37.908-1.854 55.514-1.068 19.821-16.811 35.563-36.632 36.631C131.901 187.095 110.721 188 94 188c-16.7208 0-37.9014-.905-55.5142-1.855-19.8205-1.068-35.5637-16.81-36.63201-36.631C.904831 131.908 0 110.733 0 94c0-16.733.904831-37.9078 1.85379-55.5141z" />
                                </clipPath>
                                <clipPath id="avatar-hexagon">
                                    <path d="M0 27.2891c0-4.6662 2.4889-8.976 6.52491-11.2986L31.308 1.72845c3.98-2.290382 8.8697-2.305446 12.8637-.03963l25.234 14.31558C73.4807 18.3162 76 22.6478 76 27.3426V56.684c0 4.6805-2.5041 9.0013-6.5597 11.3186L44.4317 82.2915c-3.9869 2.278-8.8765 2.278-12.8634 0L6.55974 68.0026C2.50414 65.6853 0 61.3645 0 56.684V27.2891z" />
                                </clipPath>
                            </svg>
                            <svg class="avatar avatar--80" viewBox="0 0 84 84">
                                <g class="avatar__hexagon">
                                    <image href={item.image} height="100%" width="100%" ></image>
                                </g>
                            </svg>
                            <h3 className="title title--h5">{item.author}</h3>
                            <span className="testimonial-designation">{item.designation}</span>

                            <div className=" review-item__captiontestimonial-text">
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </Slider>

            </>
        )
    }
}
export default Testmonial;