import DevWeb from "../../../../assets/pdf/certificates/Coursera DevWeb.pdf";
import DevWeb_img from "../../../../assets/images/Certificates/DevWeb.webp";

import Python1 from "../../../../assets/pdf/certificates/Coursera Python1.pdf";
import Python1_img from "../../../../assets/images/Certificates/Python1.webp";

import Python2 from "../../../../assets/pdf/certificates/Coursera Python2.pdf";
import Python2_img from "../../../../assets/images/Certificates/Python2.webp";

import Python3 from "../../../../assets/pdf/certificates/Coursera Python3.pdf";
import Python3_img from "../../../../assets/images/Certificates/Python3.webp";

import Python4 from "../../../../assets/pdf/certificates/Coursera Python4.pdf";
import Python4_img from "../../../../assets/images/Certificates/Python4.webp";

import Python5 from "../../../../assets/pdf/certificates/Coursera Python5.pdf";
import Python5_img from "../../../../assets/images/Certificates/Python5.webp";

import PythonSpecialization from "../../../../assets/pdf/certificates/Coursera PythonSpecialization.pdf";
import PythonSpecialization_img from "../../../../assets/images/Certificates/PythonSpecialization.webp";

import IEEEXtreme13 from "../../../../assets/pdf/certificates/IEEEXtreme13.0.pdf";
import IEEEXtreme13_img from "../../../../assets/images/Certificates/IEEEXtreme13.0.webp";

import IEEEXtreme14 from "../../../../assets/pdf/certificates/IEEEXtreme14.0.pdf";
import IEEEXtreme14_img from "../../../../assets/images/Certificates/IEEEXtreme14.0.webp";

import IEEEXtreme15 from "../../../../assets/pdf/certificates/IEEEXtreme15.0.pdf";
import IEEEXtreme15_img from "../../../../assets/images/Certificates/IEEEXtreme15.0.webp";

import CloudPractitioner from "../../../../assets/images/Certificates/CloudPractitioner.webp";

import terraform from "../../../../assets/images/Certificates/hashicorp-certified-terraform-associate-002.png";

export const certificates = [
  {
    title: "Hashicorp Certified Terraform Associate",
    Paragraph: "",
    Image: ` ${terraform}`,
    filesource: `https://www.credly.com/badges/5f90b943-ac92-41ca-ada7-6ac03049282a/public_url`,
  },
  {
    title: "AWS Certified Cloud Practitioner",
    Paragraph: "",
    Image: ` ${CloudPractitioner}`,
    filesource: `https://www.credly.com/badges/4eed9446-e4a4-4aa6-899b-896c19fc3d57?source=linked_in_profile`,
  },
  {
    title: "HTML, CSS, and Javascript for Web Developers",
    Paragraph:
      "an online non-credit course authorized by Johns Hopkins University and offered through Coursera",
    Image: ` ${DevWeb_img}`,
    filesource: ` ${DevWeb}`,
  },
  {
    title: "Programming for Everybody (Getting Started with Python)",
    Paragraph:
      "an online non-credit course authorized by Johns Hopkins University and offered through Coursera",
    Image: ` ${Python1_img}`,
    filesource: ` ${Python1}`,
  },
  {
    title: "Python Data Structures",
    Paragraph:
      "an online non-credit course authorized by University of Michigan and offered through Coursera",
    Image: ` ${Python2_img}`,
    filesource: ` ${Python2}`,
  },
  {
    title: "Using Python to Access Web Data ",
    Paragraph:
      "an online non-credit course authorized by University of Michigan and offered through Coursera",
    Image: ` ${Python3_img}`,
    filesource: ` ${Python3}`,
  },
  {
    title: "Using Databases with Python ",
    Paragraph:
      "an online non-credit course authorized by University of Michigan and offered through Coursera",
    Image: ` ${Python4_img}`,
    filesource: ` ${Python4}`,
  },
  {
    title: "Capstone: Retrieving, Processing, and Visualizing Data with Python",
    Paragraph:
      "an online non-credit course authorized by University of Michigan and offered through Coursera",
    Image: ` ${Python5_img}`,
    filesource: ` ${Python5}`,
  },
  {
    title: "Python for Everybody",
    Paragraph: "Python Specialization",
    Image: ` ${PythonSpecialization_img}`,
    filesource: ` ${PythonSpecialization}`,
  },
  {
    title: " IEEEXtreme 13.0 Programming Competition",
    Paragraph: "A Programming Competition that Hosted 9,400 Participants ",
    Image: ` ${IEEEXtreme13_img}`,
    filesource: ` ${IEEEXtreme13}`,
  },
  {
    title: " IEEEXtreme 14.0 Programming Competition",
    Paragraph: "A Programming Competition that Hosted 9,400 Participants ",
    Image: ` ${IEEEXtreme14_img}`,
    filesource: ` ${IEEEXtreme14}`,
  },
  {
    title: " IEEEXtreme 15.0 Programming Competition",
    Paragraph: "A Programming Competition that Hosted 9,400 Participants ",
    Image: ` ${IEEEXtreme15_img}`,
    filesource: ` ${IEEEXtreme15}`,
  },
];
