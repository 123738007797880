import React from "react";
import profileImage from "../../../assets/images/RamezBenAribia.webp";
import WebDev from "../../../assets/icons/web-programming.png";
import WebDesign from "../../../assets/icons/web-design.png";
import devops_icon from "../../../assets/icons/devops.png";

import Testmonial from "./Components/Testimonials";

import "./About.css";
import Clients from "./Components/Clients";

const About = () => {
  return (
    <>
      <div class="pb-0 pb-sm-2">
        <h1 class="title title--h1 first-title title__separate">About Me</h1>
        <p>
          As a graduating Software Engineering student, I am actively embarking
          on a transformative journey to forge a new career path. My final year
          studies have cultivated a resolute focus and an unwavering attention
          to detail, underpinning my aspiration to architect and uphold superior
          systems that transcend the expectations of end users. I am inherently
          driven by an autonomous spirit, and my affinity for people-centric
          endeavors underscores my desire to foster collaborative environments.
          Propelled by an unwavering motivation, I eagerly anticipate the
          prospect of showcasing my technical acumen and adept leadership
          skills, poised to contribute meaningfully to the dynamic landscape of
          the industry.
        </p>

        <p>
          {" "}
          Endowed with a fervent passion for DEVOPS, my professional aspirations
          are firmly rooted in securing a demanding role within a renowned
          organization. My overarching objective is to cultivate a dynamic
          environment where I can continually augment my learning curve, deepen
          my reservoir of knowledge, and hone my skill set. By embracing this
          endeavor, I seek not only personal growth but also to make a
          substantive contribution to the organization's pursuit of excellence.
        </p>
      </div>

      <div class="box-inner pb-0">
        <h2 class="title title--h3">What I'm Doing</h2>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="case-item box box__second">
              <img class="case-item__icon" src={devops_icon} alt="" />
              <div>
                <h3 class="title title--h5">DevOps</h3>
                <p class="case-item__caption">
                  Efficient development operations that can balance service
                  reliability and delivery speed.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="case-item box box__second">
              <img class="case-item__icon" src={WebDesign} alt="" />
              <div>
                <h3 class="title title--h5">Web Design</h3>
                <p class="case-item__caption">
                  The most modern and high-quality design made at a professional
                  level.
                </p>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <div class="case-item box box__second">
              <img class="case-item__icon" src={WebDev} alt="" />
              <div>
                <h3 class="title title--h5">Web Development</h3>
                <p class="case-item__caption">
                  High-quality development of sites at the professional level.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-inner box-inner--white">
        <h2 class="title title--h3">Testimonials</h2>
        <Testmonial />
      </div>

      <div class="box-inner box-inner--rounded client-section">
        <h2 class="title title--h3">Clients</h2>
        <Clients />
      </div>
    </>
  );
};

export default About;
