// technologies
import java from "../../../../assets/images/technologies/java-logo-1.png";
import docker from "../../../../assets/images/technologies/docker.webp";
import kubernetes from "../../../../assets/images/technologies/kubernetes.png";
import helm from "../../../../assets/images/technologies/helm.png";
import python from "../../../../assets/images/technologies/python.png";
import react from "../../../../assets/images/technologies/React.png";
import TypeScript from "../../../../assets/images/technologies/TypeScript.png";
import vite from "../../../../assets/images/technologies/vite.png";
import Mock_Service_Worker from "../../../../assets/images/technologies/MockServiceWorker.png";
import lambda from "../../../../assets/images/technologies/lambda.png";
import aws from "../../../../assets/images/technologies/aws.png";
import ec2 from "../../../../assets/images/technologies/ec2.svg";
import selenium from "../../../../assets/images/technologies/Selenium.png";
import flask from "../../../../assets/images/technologies/flask.png";
import neo4j from "../../../../assets/images/technologies/neo4j.png";
import redux from "../../../../assets/images/technologies/redux.png";
import nestjs from "../../../../assets/images/technologies/NestJs.png";
import githubActions from "../../../../assets/images/technologies/githubActions.png";
import ECR from "../../../../assets/images/technologies/ECR.png";
import Postgresql from "../../../../assets/images/technologies/Postgresql.png";
import spark from "../../../../assets/images/technologies/spark.png";
import hbase from "../../../../assets/images/technologies/Hbase.png";
import kafka from "../../../../assets/images/technologies/kafka.png";
import hadoop from "../../../../assets/images/technologies/hadoop.png";
import c_logo from "../../../../assets/images/technologies/c_logo.png";
import canva from "../../../../assets/images/technologies/canva.png";
import gimp from "../../../../assets/images/technologies/gimp.png";
import html from "../../../../assets/images/technologies/html.png";
import css from "../../../../assets/images/technologies/css.png";
import JavaScript from "../../../../assets/images/technologies/JavaScript.png";
import Symfony from "../../../../assets/images/technologies/Symfony.png";
import MySQL from "../../../../assets/images/technologies/MySQL.png";
import mgDB from "../../../../assets/images/technologies/mgDB.png";
import rabbitMQ from "../../../../assets/images/technologies/rabbitMQ.png";
import vue from "../../../../assets/images/technologies/vue.png";
import elasticsearch from "../../../../assets/images/technologies/elasticsearch.svg";
import NET_logo from "../../../../assets/images/technologies/NET_logo.png";
import FastAPI from "../../../../assets/images/technologies/fastAPI.png";
import odoo from "../../../../assets/images/technologies/odoo.png";
import Ansible from "../../../../assets/images/technologies/Ansible_logo.png";
import terraform from "../../../../assets/images/technologies/terraform.png";
import argocd from "../../../../assets/images/technologies/argocd.png";
import Grafana from "../../../../assets/images/technologies/Grafana.png";
import Prometheus from "../../../../assets/images/technologies/Prometheus.png";

// project images
import Gamingo1 from "../../../../assets/images/Portfolios/Gamingo/Gamingo1.webp";
import Gamingo2 from "../../../../assets/images/Portfolios/Gamingo/Gamingo2.webp";
import Gamingo3 from "../../../../assets/images/Portfolios/Gamingo/Gamingo3.webp";
import Gamingo4 from "../../../../assets/images/Portfolios/Gamingo/Gamingo4.webp";
import Gamingo5 from "../../../../assets/images/Portfolios/Gamingo/Gamingo5.webp";
import Gamingo6 from "../../../../assets/images/Portfolios/Gamingo/Gamingo6.webp";
import Gamingo7 from "../../../../assets/images/Portfolios/Gamingo/Gamingo7.webp";
import Gamingo8 from "../../../../assets/images/Portfolios/Gamingo/Gamingo8.webp";
import Gamingo9 from "../../../../assets/images/Portfolios/Gamingo/Gamingo9.webp";

import Carreau from "../../../../assets/images/Portfolios/LeCarreau/LeCarreau.webp";
import Carreau1 from "../../../../assets/images/Portfolios/LeCarreau/LeCarreau1.webp";
import Carreau2 from "../../../../assets/images/Portfolios/LeCarreau/LeCarreau2.webp";
import Carreau3 from "../../../../assets/images/Portfolios/LeCarreau/LeCarreau3.webp";
import Carreau4 from "../../../../assets/images/Portfolios/LeCarreau/LeCarreau4.webp";
import Carreau5 from "../../../../assets/images/Portfolios/LeCarreau/LeCarreau5.webp";
import Carreau6 from "../../../../assets/images/Portfolios/LeCarreau/LeCarreau6.webp";
import Carreau7 from "../../../../assets/images/Portfolios/LeCarreau/LeCarreau7.webp";

import JTI from "../../../../assets/images/Portfolios/JTI/JTI.webp";
import JTI1 from "../../../../assets/images/Portfolios/JTI/JTI1.webp";
import JTI2 from "../../../../assets/images/Portfolios/JTI/JTI2.webp";
import JTI3 from "../../../../assets/images/Portfolios/JTI/JTI3.webp";
import JTI4 from "../../../../assets/images/Portfolios/JTI/JTI4.webp";
import JTI5 from "../../../../assets/images/Portfolios/JTI/JTI5.webp";

import SYPHEMCO from "../../../../assets/images/Portfolios/SYPHEMCO/SYPHEMCO.webp";
import SYPHEMCO1 from "../../../../assets/images/Portfolios/SYPHEMCO/SYPHEMCO1.webp";
import SYPHEMCO2 from "../../../../assets/images/Portfolios/SYPHEMCO/SYPHEMCO2.webp";
import SYPHEMCO3 from "../../../../assets/images/Portfolios/SYPHEMCO/SYPHEMCO3.webp";
import SYPHEMCO4 from "../../../../assets/images/Portfolios/SYPHEMCO/SYPHEMCO4.webp";
import SYPHEMCO5 from "../../../../assets/images/Portfolios/SYPHEMCO/SYPHEMCO5.webp";
import SYPHEMCO6 from "../../../../assets/images/Portfolios/SYPHEMCO/SYPHEMCO6.webp";
import SYPHEMCO7 from "../../../../assets/images/Portfolios/SYPHEMCO/SYPHEMCO7.webp";
import SYPHEMCO8 from "../../../../assets/images/Portfolios/SYPHEMCO/SYPHEMCO8.webp";
import SYPHEMCO9 from "../../../../assets/images/Portfolios/SYPHEMCO/SYPHEMCO9.webp";
import SYPHEMCO10 from "../../../../assets/images/Portfolios/SYPHEMCO/SYPHEMCO10.webp";
import SYPHEMCO11 from "../../../../assets/images/Portfolios/SYPHEMCO/SYPHEMCO11.webp";

import ParkIt from "../../../../assets/images/Portfolios/ParkIt/ParkIt.webp";
import ParkIt2 from "../../../../assets/images/Portfolios/ParkIt/ParkIt2.webp";
import ParkIt3 from "../../../../assets/images/Portfolios/ParkIt/ParkIt3.webp";
import ParkIt4 from "../../../../assets/images/Portfolios/ParkIt/ParkIt4.webp";
import ParkIt5 from "../../../../assets/images/Portfolios/ParkIt/ParkIt5.webp";
import ParkIt6 from "../../../../assets/images/Portfolios/ParkIt/ParkIt6.webp";
import ParkIt7 from "../../../../assets/images/Portfolios/ParkIt/ParkIt7.webp";
import ParkIt8 from "../../../../assets/images/Portfolios/ParkIt/ParkIt8.webp";
import ParkIt9 from "../../../../assets/images/Portfolios/ParkIt/ParkIt9.webp";
import ParkIt10 from "../../../../assets/images/Portfolios/ParkIt/ParkIt10.webp";
import ParkIt11 from "../../../../assets/images/Portfolios/ParkIt/ParkIt11.webp";
import ParkIt12 from "../../../../assets/images/Portfolios/ParkIt/ParkIt12.webp";
import ParkIt13 from "../../../../assets/images/Portfolios/ParkIt/ParkIt13.webp";
import ParkIt14 from "../../../../assets/images/Portfolios/ParkIt/ParkIt14.webp";
import ParkIt15 from "../../../../assets/images/Portfolios/ParkIt/ParkIt15.webp";
import ParkIt16 from "../../../../assets/images/Portfolios/ParkIt/ParkIt16.webp";

import DevPack from "../../../../assets/images/Portfolios/DevPack/DevPack.webp";
import DevPack2 from "../../../../assets/images/Portfolios/DevPack/DevPack2.webp";
import DevPack3 from "../../../../assets/images/Portfolios/DevPack/DevPack3.webp";
import DevPack4 from "../../../../assets/images/Portfolios/DevPack/DevPack4.webp";
import DevPack5 from "../../../../assets/images/Portfolios/DevPack/DevPack5.webp";
import DevPack6 from "../../../../assets/images/Portfolios/DevPack/DevPack6.webp";
import DevPack7 from "../../../../assets/images/Portfolios/DevPack/DevPack7.webp";
import DevPack8 from "../../../../assets/images/Portfolios/DevPack/DevPack8.webp";
import DevPack9 from "../../../../assets/images/Portfolios/DevPack/DevPack9.webp";
import DevPack10 from "../../../../assets/images/Portfolios/DevPack/DevPack10.webp";
import DevPack11 from "../../../../assets/images/Portfolios/DevPack/DevPack11.webp";

import TunisiaStart from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart.webp";
import TunisiaStart1 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart1.webp";
import TunisiaStart2 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart2.webp";
import TunisiaStart3 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart3.webp";
import TunisiaStart4 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart4.webp";
import TunisiaStart5 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart5.webp";
import TunisiaStart6 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart6.webp";
import TunisiaStart7 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart7.webp";
import TunisiaStart8 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart8.webp";
import TunisiaStart9 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart9.webp";
import TunisiaStart10 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart10.webp";
import TunisiaStart11 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart11.webp";
import TunisiaStart12 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart12.webp";
import TunisiaStart13 from "../../../../assets/images/Portfolios/TunisiaStart/TunisiaStart13.webp";

import BO_HO from "../../../../assets/images/Portfolios/BO_HO/BO_HO.webp";
import BO_HO2 from "../../../../assets/images/Portfolios/BO_HO/BO_HO2.webp";
import BO_HO3 from "../../../../assets/images/Portfolios/BO_HO/BO_HO3.webp";

import RabbitMQ from "../../../../assets/images/Portfolios/RabbitMQ/RabbitMQ.webp";
import RabbitMQ2 from "../../../../assets/images/Portfolios/RabbitMQ/RabbitMQ2.webp";
import RabbitMQ3 from "../../../../assets/images/Portfolios/RabbitMQ/RabbitMQ3.webp";
import RabbitMQ4 from "../../../../assets/images/Portfolios/RabbitMQ/RabbitMQ4.webp";

import Ramez from "../../../../assets/images/Portfolios/RamezBenAribiaV1/Ramez.jpg";
import Ramez2 from "../../../../assets/images/Portfolios/RamezBenAribiaV1/Ramez2.jpg";
import Ramez3 from "../../../../assets/images/Portfolios/RamezBenAribiaV1/Ramez3.jpg";
import Ramez4 from "../../../../assets/images/Portfolios/RamezBenAribiaV1/Ramez4.jpg";
import Ramez5 from "../../../../assets/images/Portfolios/RamezBenAribiaV1/Ramez5.jpg";
import Ramez6 from "../../../../assets/images/Portfolios/RamezBenAribiaV1/Ramez6.jpg";
import Ramez7 from "../../../../assets/images/Portfolios/RamezBenAribiaV1/Ramez7.jpg";

import SEMAT from "../../../../assets/images/Portfolios/SEMAT/semat.webp";
import SEMAT2 from "../../../../assets/images/Portfolios/SEMAT/semat2.webp";
import SEMAT3 from "../../../../assets/images/Portfolios/SEMAT/semat3.webp";
import SEMAT4 from "../../../../assets/images/Portfolios/SEMAT/semat4.webp";
import SEMAT5 from "../../../../assets/images/Portfolios/SEMAT/semat5.webp";
import SEMAT6 from "../../../../assets/images/Portfolios/SEMAT/semat6.webp";
import SEMAT7 from "../../../../assets/images/Portfolios/SEMAT/semat7.webp";
import SEMAT8 from "../../../../assets/images/Portfolios/SEMAT/semat8.webp";
import SEMAT9 from "../../../../assets/images/Portfolios/SEMAT/semat9.webp";
import SEMAT10 from "../../../../assets/images/Portfolios/SEMAT/semat10.webp";
import SEMAT11 from "../../../../assets/images/Portfolios/SEMAT/semat11.webp";
import SEMAT12 from "../../../../assets/images/Portfolios/SEMAT/semat12.webp";
import SEMAT13 from "../../../../assets/images/Portfolios/SEMAT/semat13.webp";
import SEMAT14 from "../../../../assets/images/Portfolios/SEMAT/semat14.webp";
import SEMAT15 from "../../../../assets/images/Portfolios/SEMAT/semat15.webp";
import SEMAT16 from "../../../../assets/images/Portfolios/SEMAT/semat16.webp";
import SEMAT17 from "../../../../assets/images/Portfolios/SEMAT/semat17.webp";

import RBA from "../../../../assets/images/Portfolios/RBALogo/RBA.png";

import LaCasaDePadel from "../../../../assets/images/Portfolios/LaCasaDePadel/LaCasaDePadel.jpg";

import PFA from "../../../../assets/images/Portfolios/PFA/LOGO.jpg";

import WassimLogo from "../../../../assets/images/Portfolios/Wassim/Wassimlogo.png";

import WassimCarteVisite from "../../../../assets/images/Portfolios/Wassim/WassimCarteVisite.jpg";
import WassimCarteVisite2 from "../../../../assets/images/Portfolios/Wassim/WassimCarteVisite2.jpg";

import mini_java_compiler from "../../../../assets/images/Portfolios/MiniJavaCompiler/mini_java_compiler.webp";

import big_data_pipline from "../../../../assets/images/Portfolios/BigDataPipline/big_data_pipline.png";

import todo_app from "../../../../assets/images/Portfolios/ToDoApp/todo_app.png";

import devops_pipeline from "../../../../assets/images/Portfolios/Software-Testing-Devops-Pipeline/devops_pipline.png";

import PFA_Search_Engine_1 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_1.webp";
import PFA_Search_Engine_2 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_2.webp";
import PFA_Search_Engine_3 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_3.webp";
import PFA_Search_Engine_4 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_4.webp";
import PFA_Search_Engine_5 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_5.webp";
import PFA_Search_Engine_6 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_6.webp";
import PFA_Search_Engine_7 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_7.webp";
import PFA_Search_Engine_8 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_8.webp";
import PFA_Search_Engine_9 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_9.webp";
import PFA_Search_Engine_10 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_10.webp";
import PFA_Search_Engine_11 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_11.webp";
import PFA_Search_Engine_12 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_12.webp";
import PFA_Search_Engine_13 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_13.webp";
import PFA_Search_Engine_14 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_14.webp";
import PFA_Search_Engine_15 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_15.webp";
import PFA_Search_Engine_16 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_16.webp";
import PFA_Search_Engine_17 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_17.webp";
import PFA_Search_Engine_18 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_18.webp";
import PFA_Search_Engine_19 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_19.webp";
import PFA_Search_Engine_20 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_20.webp";
import PFA_Search_Engine_21 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_21.webp";
import PFA_Search_Engine_22 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_22.webp";
import PFA_Search_Engine_23 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_23.webp";
import PFA_Search_Engine_24 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_24.webp";
import PFA_Search_Engine_25 from "../../../../assets/images/Portfolios/PFA-Search-Engine/PFA_Search_Engine_25.webp";

import k8s_demo_mongo from "../../../../assets/images/Portfolios/Kubernetes-Demo-Mongo/kubernetes-logo-big.png";

import StarWars1 from "../../../../assets/images/Portfolios/StarWars/StarWars1.png";
import StarWars2 from "../../../../assets/images/Portfolios/StarWars/StarWars2.png";

import MyCarRent from "../../../../assets/images/Portfolios/MyCarRent/MyCarRent.jpg";

import matchingApp1 from "../../../../assets/images/Portfolios/MatchingApp/matchingApp1.png";
import matchingApp2 from "../../../../assets/images/Portfolios/MatchingApp/matchingApp2.png";
import matchingApp3 from "../../../../assets/images/Portfolios/MatchingApp/matchingApp3.png";
import matchingApp4 from "../../../../assets/images/Portfolios/MatchingApp/matchingApp4.png";

import Odoo_Operator from "../../../../assets/images/Portfolios/Odoo_Operator/architecture.png";

import Azure_miniProject from "../../../../assets/images/Portfolios/Azure_miniProject/architecture.png";

export const portfolios = [
  // {
  //   id: 1,
  //   title: "J T I",
  //   type: "Theater",

  //   featuredImage: ` ${JTI}`,
  //   images: [`${JTI}`, `${JTI1}`, `${JTI2}`, `${JTI3}`, `${JTI4}`, `${JTI5}`],

  //   URL: "",
  //   description7:
  //     "JTI as the 'journée théâtrale insat' is one of the most famous events in INSAT, and I was honored personally to be a member of that club and to participate in this grateful event like an actor, a writer and organizer it was one of the greatest moments and event in my life, and I am so happy to have an experience in theater and specially in 'theatro INSAT'",
  //   createDay: "1",
  //   createMonth: "February",
  //   createYear: "2020",
  // },

  // {
  //   id: 2,
  //   title: "Le Carreau",
  //   type: "Theater",

  //   featuredImage: ` ${Carreau}`,
  //   images: [
  //     `${Carreau}`,
  //     `${Carreau1}`,
  //     `${Carreau2}`,
  //     `${Carreau3}`,
  //     `${Carreau4}`,
  //     `${Carreau5}`,
  //     `${Carreau6}`,
  //     `${Carreau7}`,
  //   ],
  //   URL: "https://www.facebook.com/Le-Carreau-%D8%B1%D9%82%D8%B9%D8%A9-104766997774573",
  //   description1:
  //     "'Le carreau', a play developed by members of the 'Theatro Insat' club, was presented on 29 February 2020 for the first time outside the university walls, at the L'Etoile du Nord theater.",
  //   description2:
  //     "'Le Carreau' is a collective work of young scientific students, future engineers, but also lovers of the arts and, especially, the theater. Some of them have taken theater courses to better develop their skills and acquire new techniques. The direction and the scenography are the result of the collaboration of actors Houssem Chabbouh, Amani Melliti, Mehdi Mejdoub, Ranya Raffa, Amine S'hiri and Asma Ben Neji.",
  //   description3:
  //     "For an hour and a half, we follow Ammar, a young man born in the United States, on his journey to his native country, following President Trump's decision to attack Tunisia. The young man meets five characters who will play more than 40 different roles, and this is where he will understand everything.",
  //   description4:
  //     "Six students from the Insat, all dressed in black, their actions take place in a 4 square meter tile. They play 40 characters and 40 different colors. One has the impression that one is facing one body, one soul and one vision.",
  //   description5:
  //     "The concept of the play is inspired by the daily life of Tunisians and these students in particular. 'The tile' means, according to these young budding talents, Tunisia. A space that we love in spite of everything and that we defend all the time. The play is a social criticism of the social phenomena we face today. It is also an invitation to Tunisians to take a step back, to reconsider their choices and their actions in order to move forward.",
  //   description6:
  //     "This work is based on Polish director Jerzy Grotowski's concept of poor theater, where the actor's acting and technique take precedence over costumes, sets and lighting. This concept thus highlights the actor's body.",
  //   description7:
  //     "The play, which won first prize in the 'Engineering Got Talent' competition at the Supcom school with the great actress Jouda Najeh, will be accompanied by the live music of Ramez Ben Aribia, also a student at the Insat. The latter also represents a character, 'Derbekji', a mute character. But he is out of the window. Being fed up with Tunisian society, he decided to get out of the box and use music as a means of denunciation, criticism and protest against this situation. Throughout the piece, he expresses his different emotions ranging from grief, anger and sarcasm.",
  //   description8:
  //     "A first presentation of the play adapted by actor Taoufik Bahri will take place outside the walls of the universities and especially at the Théâtre du Nord, the space that celebrates theater, music, literature and art in general. Multiple transitions, a synchronized musical improvisation and an original performance will be part of the show.",
  //   createDay: "29",
  //   createMonth: "February",
  //   createYear: "2020",
  // },

  {
    id: 3,
    title: "RabbitMQ Application",
    type: "Programming",

    featuredImage: ` ${BO_HO}`,
    images: [`${BO_HO}`, `${BO_HO2}`, `${BO_HO3}`],
    URL: "https://github.com/ramezbenaribia/RabbitMQ_BO_HO",
    technologies: [
      {
        id: 1,
        pic: rabbitMQ,
        name: "Rabbit MQ",
      },
      {
        id: 2,
        pic: java,
        name: "Java",
      },
    ],

    description1: "A small application that uses java and rabbitMQ",
    description7:
      "This application connects two databases and merge their data to database which called “HO”",
    createDay: "28",
    createMonth: "April",
    createYear: "2021",
  },

  {
    id: 4,
    title: "RabbitMQ Communication Application",
    type: "Programming",

    featuredImage: ` ${RabbitMQ4}`,
    images: [`${RabbitMQ4}`, `${RabbitMQ}`, `${RabbitMQ2}`, `${RabbitMQ3}`],
    URL: "https://github.com/ramezbenaribia/RabbitMQ",
    technologies: [
      {
        id: 1,
        pic: rabbitMQ,
        name: "Rabbit MQ",
      },
      {
        id: 2,
        pic: java,
        name: "Java",
      },
    ],

    description1: "A small application that uses java and rabbitMQ",
    description7:
      "This application connects many users throw RabbitMQ and allows them to communicate ",
    createDay: "10",
    createMonth: "March",
    createYear: "2021",
  },

  {
    id: 5,
    title: "Gamingo",
    type: "website",

    featuredImage: ` ${Gamingo1}`,
    images: [
      `${Gamingo1}`,
      `${Gamingo2}`,
      `${Gamingo3}`,
      `${Gamingo4}`,
      `${Gamingo5}`,
      `${Gamingo6}`,
      `${Gamingo7}`,
      `${Gamingo8}`,
      `${Gamingo9}`,
    ],
    URL: "https://github.com/ramezbenaribia/Gamingo",
    technologies: [
      {
        id: 1,
        pic: html,
        name: "HTML",
      },
      {
        id: 2,
        pic: css,
        name: "CSS",
      },
      {
        id: 3,
        pic: JavaScript,
        name: "JavaScript",
      },
      {
        id: 4,
        pic: Symfony,
        name: "Symfony",
      },
      {
        id: 5,
        pic: MySQL,
        name: "MySQL",
      },
    ],
    description1:
      " Gamingo’s mission is to gather the world's top video games in one place.",

    description2:
      " We enable you to explore them, rate them, and play some of our games , all built by a group of GL2 developers",
    description3:
      "Powered by Creators  is powered by a small community of four developers.",
    description7:
      " Ramez Ben Aribia, Ghassen Daoud, Mahdi Hamdi and Wafa Selmi who designed this website for a school project.",
    createDay: "20",
    createMonth: "May",
    createYear: "2020",
  },

  {
    id: 6,
    title: "TunisiaStart",
    type: "website",

    featuredImage: ` ${TunisiaStart}`,
    images: [
      `${TunisiaStart}`,
      `${TunisiaStart1}`,
      `${TunisiaStart2}`,
      `${TunisiaStart3}`,
      `${TunisiaStart4}`,
      `${TunisiaStart5}`,
      `${TunisiaStart6}`,
      `${TunisiaStart7}`,
      `${TunisiaStart8}`,
      `${TunisiaStart9}`,
      `${TunisiaStart10}`,
      `${TunisiaStart11}`,
      `${TunisiaStart12}`,
      `${TunisiaStart13}`,
    ],
    URL: "https://tunisia-start-ramezbenaribia.vercel.app/",
    technologies: [
      {
        id: 1,
        pic: react,
        name: "React",
      },
    ],
    description1: "It was a great team effort, all together",
    description2:
      "On February the 27th/28th, my team won second place in our first hackathon!",
    description3:
      " “Beyond the Stars” is a business and IT Hackathon organized every year by ATLAS Future Leaders-TBS Chapter. This year’s theme was “e-governance G2B”.",
    description4:
      " In 22 hours, we created a web platform that facilitates the process of creating your own business in order to ensure economic growth in our beloved country.",
    description5:
      " I would like to thank my amazing teammates for that experience; Souha Ben Hassine, Amine Haj ALi and Oussema Ghorbel.",
    description6:
      "I would also like to thank the organizing committee for their outstanding work and continuous support.",
    description7:
      " Our first hackathon was a great success, and the best is yet to come!",
    createDay: "27-28",
    createMonth: "February",
    createYear: "2021",
  },

  {
    id: 7,
    title: "RamezBenAribia-V1",
    type: "website",

    featuredImage: ` ${Ramez}`,
    images: [
      `${Ramez}`,
      `${Ramez2}`,
      `${Ramez3}`,
      `${Ramez4}`,
      `${Ramez5}`,
      `${Ramez6}`,
      `${Ramez7}`,
    ],
    URL: "",
    technologies: [
      {
        id: 1,
        pic: react,
        name: "React",
      },
    ],
    description7: "This is the first virsion of my website ",
    createDay: "27-28",
    createMonth: "February",
    createYear: "2021",
  },

  {
    id: 8,

    title: "SYPHEMCO",
    type: "website",

    featuredImage: ` ${SYPHEMCO}`,
    images: [
      `${SYPHEMCO}`,
      `${SYPHEMCO1}`,
      `${SYPHEMCO2}`,
      `${SYPHEMCO3}`,
      `${SYPHEMCO4}`,
      `${SYPHEMCO5}`,
      `${SYPHEMCO6}`,
      `${SYPHEMCO7}`,
      `${SYPHEMCO8}`,
      `${SYPHEMCO9}`,
      `${SYPHEMCO10}`,
      `${SYPHEMCO11}`,
    ],
    URL: "https://syphemco.com/",
    technologies: [
      {
        id: 1,
        pic: react,
        name: "React",
      },
    ],
    description1:
      "This is a website created by me Ben Aribia Ramez And Hamdi Mahdi  ",

    description7:
      " SYPHEMCO is a company working in the oil service and construction sector.",
    createDay: "10",
    createMonth: "March",
    createYear: "2021",
  },

  {
    id: 9,
    title: "ParkIt",
    type: "website",

    featuredImage: ` ${ParkIt}`,
    images: [
      `${ParkIt}`,
      `${ParkIt2}`,
      `${ParkIt3}`,
      `${ParkIt4}`,
      `${ParkIt5}`,
      `${ParkIt6}`,
      `${ParkIt7}`,
      `${ParkIt8}`,
      `${ParkIt9}`,
      `${ParkIt10}`,
      `${ParkIt11}`,
      `${ParkIt12}`,
      `${ParkIt13}`,
      `${ParkIt14}`,
      `${ParkIt15}`,
      `${ParkIt16}`,
    ],
    URL: "https://park-it-blue.vercel.app/home",
    technologies: [
      {
        id: 1,
        pic: react,
        name: "React",
      },
      {
        id: 2,
        pic: nestjs,
        name: "NestJs",
      },
      {
        id: 3,
        pic: mgDB,
        name: "Mongo DB",
      },
    ],
    description1:
      "This is a website created by me Ben Aribia Ramez And Hamdi Mahdi and Souha Ben Hassine ",
    description7:
      "a platform for the management, consultation, and reservation of public and private parking spaces in Tunisia.",
    createDay: "12",
    createMonth: "May",
    createYear: "2021",
  },

  {
    id: 10,
    title: "MatchingApp",
    type: "website",

    featuredImage: ` ${matchingApp4}`,
    images: [
      `${matchingApp1}`,
      `${matchingApp2}`,
      `${matchingApp3}`,
      `${matchingApp4}`,
    ],
    URL: "https://github.com/MatchingApp/MatchingApp_Front",
    technologies: [
      {
        id: 1,
        pic: NET_logo,
        name: ".NET Framework",
      },
      {
        id: 2,
        pic: vue,
        name: "Vue",
      },
      {
        id: 3,
        pic: elasticsearch,
        name: "Elastic Search",
      },
    ],
    description1:
      "Worked on a profile/Job matching web-based service with VueJs, .Net and ElasticSearch.",
    description7:
      "The service aims to find the best match for the job among the companies list of consultants/candidates.",
    description8: "Worked on Elasticsearch Cluster Management.",
    createDay: "14",
    createMonth: "September",
    createYear: "2021",
  },

  {
    id: 11,
    title: "DevPack",
    type: "website",
    featuredImage: ` ${DevPack}`,
    images: [
      `${DevPack}`,
      `${DevPack2}`,
      `${DevPack3}`,
      `${DevPack4}`,
      `${DevPack5}`,
      `${DevPack6}`,
      `${DevPack7}`,
      `${DevPack8}`,
      `${DevPack9}`,
      `${DevPack10}`,
      `${DevPack11}`,
    ],
    URL: "http://devpack.com.tn/",
    technologies: [
      {
        id: 1,
        pic: react,
        name: "React",
      },
    ],
    description1:
      "This is a website created by me Ben Aribia Ramez And Hamdi Mahdi  ",

    description7:
      "DEVPACK is an industrial company offering standard or customized plastic packaging based on XPS polystyrene foam. It innovates to develop packaging based on XPS polystyrene foam for its customers while respecting national and international standards.",
    createDay: "12",
    createMonth: "October",
    createYear: "2021",
  },

  {
    id: 12,
    title: "SEMAT",
    type: "website",
    featuredImage: ` ${SEMAT}`,
    images: [
      `${SEMAT}`,
      `${SEMAT2}`,
      `${SEMAT3}`,
      `${SEMAT4}`,
      `${SEMAT5}`,
      `${SEMAT6}`,
      `${SEMAT7}`,
      `${SEMAT8}`,
      `${SEMAT9}`,
      `${SEMAT10}`,
      `${SEMAT11}`,
      `${SEMAT12}`,
      `${SEMAT13}`,
      `${SEMAT14}`,
      `${SEMAT16}`,
      `${SEMAT17}`,
      `${SEMAT15}`,
    ],
    URL: "http://www.semat.com.tn/",
    technologies: [
      {
        id: 1,
        pic: react,
        name: "React",
      },
    ],
    description1:
      "This is a website created by me Ben Aribia Ramez And Hamdi Mahdi and Mahdi Bouazi ",

    description7: "SEMAT is an industrial company. ",
    createDay: "12",
    createMonth: "October",
    createYear: "2021",
  },

  {
    id: 13,
    title: "RBA",
    type: "logo",
    featuredImage: ` ${RBA}`,
    images: [`${RBA}`],
    URL: "",
    technologies: [
      {
        id: 1,
        pic: canva,
        name: "Canva",
      },
      {
        id: 2,
        pic: gimp,
        name: "Gimp",
      },
    ],
    description7: "My personal LOGO ",
    createDay: "12",
    createMonth: "October",
    createYear: "2021",
  },

  {
    id: 14,
    title: "LaCasaDePadel",
    type: "logo",
    featuredImage: ` ${LaCasaDePadel}`,
    images: [`${LaCasaDePadel}`],
    URL: "",
    technologies: [
      {
        id: 1,
        pic: canva,
        name: "Canva",
      },
      {
        id: 2,
        pic: gimp,
        name: "Gimp",
      },
    ],
    description7: "a logo for club of padel",
    createDay: "12",
    createMonth: "October",
    createYear: "2021",
  },

  {
    id: 15,
    title: "PFA",
    type: "logo",
    featuredImage: ` ${PFA}`,
    images: [`${PFA}`],
    URL: "",
    technologies: [
      {
        id: 1,
        pic: canva,
        name: "Canva",
      },
      {
        id: 2,
        pic: gimp,
        name: "Gimp",
      },
    ],
    description7: "it's a logo for a project at  the universty ",
    createDay: "12",
    createMonth: "October",
    createYear: "2021",
  },

  {
    id: 16,
    title: "WassimLogo",
    type: "logo",
    featuredImage: ` ${WassimLogo}`,
    images: [`${WassimLogo}`],
    URL: "",
    technologies: [
      {
        id: 1,
        pic: canva,
        name: "Canva",
      },
      {
        id: 2,
        pic: gimp,
        name: "Gimp",
      },
    ],
    description7: "A logo for a dentist",
    createDay: "12",
    createMonth: "October",
    createYear: "2021",
  },

  {
    id: 17,
    title: "Wassim Business Card",
    type: "Design",
    featuredImage: ` ${WassimCarteVisite}`,
    images: [`${WassimCarteVisite}`, `${WassimCarteVisite2}`],
    URL: "",
    technologies: [
      {
        id: 1,
        pic: canva,
        name: "Canva",
      },
    ],
    description7: "A business card  for a dentist",
    createDay: "12",
    createMonth: "October",
    createYear: "2021",
  },

  {
    id: 18,
    title: "Mini Java Compiler",
    type: "Programming",
    featuredImage: ` ${mini_java_compiler}`,
    images: [`${mini_java_compiler}`],
    URL: "https://github.com/ramezbenaribia/miniJava-compiler",
    technologies: [
      {
        id: 1,
        pic: c_logo,
        name: "C",
      },
      {
        id: 2,
        pic: python,
        name: "Python",
      },
    ],
    description7: "A mini Java Compiler written with C",
    createDay: "2",
    createMonth: "May",
    createYear: "2022",
  },

  {
    id: 19,
    title: "Big Data Pipeline",
    type: "DevOps",
    featuredImage: ` ${big_data_pipline}`,
    images: [`${big_data_pipline}`],
    URL: "https://github.com/ramezbenaribia/Big-Data-Pipeline",
    technologies: [
      {
        id: 1,
        pic: docker,
        name: "Docker",
      },
      {
        id: 2,
        pic: spark,
        name: "APACHE Spark",
      },

      {
        id: 3,
        pic: hbase,
        name: "APACHE Hbase",
      },
      {
        id: 4,
        pic: kafka,
        name: "Kafka",
      },
      {
        id: 5,
        pic: hadoop,
        name: "Hadoop",
      },
      {
        id: 6,
        pic: java,
        name: "Java",
      },
      {
        id: 7,
        pic: python,
        name: "Python",
      },
    ],
    description7: "Prediction Of Tennis Matches Winner",
    description8:
      "This is a pipeline that uses many Big Data technologies to predict results of a tennis match.",
    createDay: "2",
    createMonth: "May",
    createYear: "2022",
  },

  {
    id: 20,
    title: "Software Testing Devops Pipeline",
    type: "DevOps",
    featuredImage: ` ${devops_pipeline}`,
    images: [`${devops_pipeline}`],
    URL: "https://github.com/ramezbenaribia/Software-Testing-Devops-Pipeline",
    technologies: [
      {
        id: 1,
        pic: nestjs,
        name: "NestJs",
      },
      {
        id: 2,
        pic: githubActions,
        name: "GIT Actions",
      },

      {
        id: 3,
        pic: Postgresql,
        name: "Postgresql",
      },
      {
        id: 4,
        pic: docker,
        name: "Docker",
      },
      {
        id: 5,
        pic: aws,
        name: "AWS",
      },
      {
        id: 6,
        pic: ECR,
        name: "ECR",
      },
    ],

    description1:
      "This project is my submission for our DevOps & Software Testing's workshop at INSAT.",
    description2:
      "We were required to come up with a project, implement its Unit Tests, Integration Tests and E2E tests, then implement a CI/CD pipeline.",
    description7:
      "The project is a simple NestJs application that communicates with an AWS  ECR REGISTRY",

    createDay: "6",
    createMonth: "June",
    createYear: "2022",
  },
  {
    id: 21,
    title: "To Do App",
    type: "website",
    featuredImage: ` ${todo_app}`,
    images: [`${todo_app}`],
    URL: "https://todo-app-beta-rust.vercel.app/",

    technologies: [
      {
        id: 1,
        pic: react,
        name: "React",
      },
      {
        id: 2,
        pic: redux,
        name: "Redux",
      },
    ],

    description7: "A  tech challenge for a small task management application",
    description2: "Required features:",
    description3: `
    - React and TypeScript should be used for the implementation \n
    - The application should be a frontend-only solution without any external API
    - It should be documented how the application is able to start a development server to run your app on a local machine
    - The user should be able to create new tasks (just a title is required), mark existing tasks as completed and list existing tasks`,
    description4: "Optional features:",
    description5: `
    - In case of reloading the browser window, the tasks should be still available
    - Implement basic unit/component tests
    - Configure and use a linter`,
    createDay: "24",
    createMonth: "June",
    createYear: "2022",
  },

  {
    id: 22,
    title: "ETL, Search Engine for internships",
    type: "DevOps",
    featuredImage: ` ${PFA}`,
    images: [
      `${PFA_Search_Engine_1}`,
      `${PFA_Search_Engine_2}`,
      `${PFA_Search_Engine_3}`,
      `${PFA_Search_Engine_4}`,
      `${PFA_Search_Engine_5}`,
      `${PFA_Search_Engine_6}`,
      `${PFA_Search_Engine_7}`,
      `${PFA_Search_Engine_8}`,
      `${PFA_Search_Engine_9}`,
      `${PFA_Search_Engine_10}`,
      `${PFA_Search_Engine_11}`,
      `${PFA_Search_Engine_12}`,
      `${PFA_Search_Engine_13}`,
      `${PFA_Search_Engine_14}`,
      `${PFA_Search_Engine_15}`,
      `${PFA_Search_Engine_16}`,
      `${PFA_Search_Engine_17}`,
      `${PFA_Search_Engine_18}`,
      `${PFA_Search_Engine_19}`,
      `${PFA_Search_Engine_20}`,
      `${PFA_Search_Engine_21}`,
      `${PFA_Search_Engine_22}`,
      `${PFA_Search_Engine_23}`,
      `${PFA_Search_Engine_24}`,
      `${PFA_Search_Engine_25}`,
    ],

    URL: "https://github.com/ramezbenaribia/pfe-search-engine",

    technologies: [
      {
        id: 1,
        pic: react,
        name: "React",
      },
      {
        id: 2,
        pic: docker,
        name: "Docker",
      },
      {
        id: 3,
        pic: python,
        name: "Python",
      },
      {
        id: 4,
        pic: flask,
        name: "Flask",
      },
      {
        id: 5,
        pic: aws,
        name: "AWS",
      },
      {
        id: 6,
        pic: ec2,
        name: "EC2",
      },
      {
        id: 7,
        pic: lambda,
        name: "Lambda function",
      },
      {
        id: 8,
        pic: neo4j,
        name: "Neo4j",
      },
      {
        id: 9,
        pic: selenium,
        name: "Selenium",
      },
    ],

    description7: `The idea of the project is to create an intelligent search engine centralizing most of
    internship offers as well as all details needed for students to land the internship they
    want`,

    createDay: "6",
    createMonth: "June",
    createYear: "2022",
  },

  {
    id: 23,
    title: "Kubernetes Demo Mongo",
    type: "DevOps",
    featuredImage: ` ${k8s_demo_mongo}`,
    images: [`${k8s_demo_mongo}`],
    URL: "https://github.com/ramezbenaribia/Kubernetes-demo-mongo",

    technologies: [
      {
        id: 1,
        pic: kubernetes,
        name: "Kubernetes",
      },
      {
        id: 2,
        pic: docker,
        name: "Docker",
      },
    ],
    description7:
      "A small project for demonstrating the functionality of  Kubernetes with a mongoDB image",
    createDay: "7",
    createMonth: "July",
    createYear: "2022",
  },

  {
    id: 24,
    title: "Star Wars",
    type: "website",
    featuredImage: ` ${StarWars1}`,
    images: [`${StarWars1}`, `${StarWars2}`],
    URL: "https://star-wars-mu-two.vercel.app/",
    technologies: [
      {
        id: 1,
        pic: react,
        name: "React",
      },
      {
        id: 2,
        pic: vite,
        name: "Vite",
      },
      {
        id: 3,
        pic: TypeScript,
        name: "TypeScript",
      },
      {
        id: 4,
        pic: Mock_Service_Worker,
        name: "Mock Service Worker",
      },
    ],

    description7: "A tech challenge for “Star Wars Information” application",
    createDay: "13",
    createMonth: "July",
    createYear: "2022",
  },

  {
    id: 25,
    title: "Kubernetes Operator",
    type: "DevOps",
    technologies: [
      {
        id: 1,
        pic: python,
        name: "Python",
      },
      {
        id: 2,
        pic: kubernetes,
        name: "Kubernetes",
      },
      {
        id: 3,
        pic: docker,
        name: "Docker",
      },
      {
        id: 4,
        pic: helm,
        name: "Helm",
      },
    ],

    featuredImage: ` ${k8s_demo_mongo}`,
    images: [`${k8s_demo_mongo}`],
    URL: "https://github.com/ramezbenaribia/kubernetes-operator",

    description7:
      "A small project for demonstrating the functionality of an operator using python and FastAPI to recieve the requests",
    createDay: "13",
    createMonth: "July",
    createYear: "2022",
  },

  {
    id: 26,
    title: "My Car Rent",
    type: "DevOps",
    technologies: [
      {
        id: 1,
        pic: java,
        name: "Java",
      },
      {
        id: 2,
        pic: docker,
        name: "Docker",
      },
    ],
    featuredImage: ` ${MyCarRent}`,
    images: [`${MyCarRent}`],
    URL: "https://github.com/ramezbenaribia/MyCarRent",

    description7:
      "This project is a small applictaion that represent an online booking system for car renting, named MyCar.",
    createDay: "6",
    createMonth: "september",
    createYear: "2022",
  },

  {
    id: 27,
    title: "Odoo Operator",
    type: "DevOps",
    technologies: [
      {
        id: 1,
        pic: python,
        name: "Python",
      },
      {
        id: 2,
        pic: FastAPI,
        name: "FastAPI",
      },
      {
        id: 3,
        pic: docker,
        name: "Docker",
      },
      {
        id: 4,
        pic: kubernetes,
        name: "Kubernetes",
      },

      {
        id: 5,
        pic: helm,
        name: "HELM",
      },

      {
        id: 6,
        pic: odoo,
        name: "Odoo",
      },
    ],
    featuredImage: ` ${Odoo_Operator}`,
    images: [`${Odoo_Operator}`],
    URL: "https://github.com/ramezbenaribia/Odoo-Operator",

    description1:
      "Automate Odoo deployment and scalability on kubernetes cluster.",
    description2: "Built an operator that manages CRDs for Odoo deployment.",
    description7: `Slashed deployment time 90% by reducing manual interaction with kuberntes cluster,
    while ensuring the continuation and enhancements of services.`,
    description8:
      "Documented the Project so it can be understandable and reusable for the company.",
    createDay: "8",
    createMonth: "October",
    createYear: "2022",
  },

  {
    id: 28,
    title: "AZURE Mini Project",
    type: "DevOps",
    technologies: [
      {
        id: 1,
        pic: terraform,
        name: "Terraform",
      },
      {
        id: 2,
        pic: Ansible,
        name: "Ansible",
      },
      {
        id: 3,
        pic: kubernetes,
        name: "Kubernetes",
      },

      {
        id: 4,
        pic: helm,
        name: "HELM",
      },
    ],
    featuredImage: ` ${Azure_miniProject}`,
    images: [`${Azure_miniProject}`],
    URL: "https://github.com/ramezbenaribia/Azure_cloud/tree/main",

    description1:
      "This is a compact but impressive project that focuses on deploying a concise application designed to encode and decode URLs.",
    description2:
      " The project excels in its extensive involvement of Azure services and leverages their full potential. As part of this, we introduced orchestration of Kubernetes, Terraform and Ansible, resulting in a harmonious synergy that seamlessly automates and streamlines the entire operational spectrum.>",
    description7: ` With this carefully structured approach, our goal is to achieve optimal efficiency while leveraging the comprehensive capabilities offered by Azure's range of services`,
    createDay: "8",
    createMonth: "October",
    createYear: "2022",
  },

  {
    id: 29,
    title: "Casa Padel Monitored",
    type: "DevOps",
    technologies: [
      {
        id: 1,
        pic: Prometheus,
        name: "Prometheus",
      },
      {
        id: 2,
        pic: Grafana,
        name: "Grafana",
      },
      {
        id: 3,
        pic: argocd,
        name: "Argocd",
      },

      {
        id: 4,
        pic: kubernetes,
        name: "Kubernetes",
      },
      {
        id: 5,
        pic: docker,
        name: "Docker",
      },
      {
        id: 6,
        pic: helm,
        name: "Helm",
      },
    ],

    featuredImage: ` ${k8s_demo_mongo}`,
    images: [`${k8s_demo_mongo}`],
    URL: "https://github.com/mahdi-hamdii/casa-padel-sfax-monitored",

    description1:
      "This learning project concerns a compact but transformative initiative in which a colleague and I collaborated to split a monolithic application into agile microservices.",
    description2:
      "Subsequent deployment was carefully orchestrated in Kubernetes.",
    description3:
      "In this orchestration, we skilfully exploited two different paths - Azure Kubernetes Service (AKS) and Kubeadm.",
    description4:
      "As part of our global monitoring efforts, we cleverly integrated Prometheus and Grafana Loki to capture logs and traces, with Grafana serving as the central point for a comprehensive visual dashboard.",
    description5:
      "At the same time, we've extended our scope to include monitoring of Kubernetes clusters, and seamlessly integrated our project with ArgoCD.",
    description6:
      "This key integration enables us to keep a watchful eye on the complex nuances of our Kubernetes clusters, further increasing the educational depth of the project and its applicability in real-world scenarios.",
    description7:
      "Extra links To the project: •  https://github.com/mahdi-hamdii/DevOps-GL5 •  https://github.com/mahdi-hamdii/kubeadm-terraform-ansible",
    createDay: "13",
    createMonth: "July",
    createYear: "2022",
  },
];
