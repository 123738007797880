export const skills = [
  {
    skillType: "DevOps Tools",
    skills: [
      {
        title: "Docker",
        percentage: "70",
      },
      {
        title: "Docker-compose",
        percentage: "80",
      },
      {
        title: "Jenkins",
        percentage: "40",
      },
      {
        title: "Kubernetes",
        percentage: "30",
      },
      {
        title: "Terraform",
        percentage: "60",
      },
      {
        title: "Nginx",
        percentage: "40",
      },
      {
        title: "Version Control",
        percentage: "90",
      },
      {
        title: "Linux",
        percentage: "50",
      },
      {
        title: "Prometheus",
        percentage: "30",
      },
      {
        title: "Grafana",
        percentage: "30",
      },
      {
        title: "ArgoCD",
        percentage: "30",
      },
    ],
  },

  {
    skillType: "Cloud Providers",
    skills: [
      {
        title: "AWS(Intermediate)",
        percentage: "45",
      },
      {
        title: "Azure(Intermediate)",
        percentage: "35",
      },
    ],
  },
  {
    skillType: "Programming Languages",
    skills: [
      {
        title: "Javascript",
        percentage: "80",
      },
      {
        title: "Python",
        percentage: "75",
      },

      {
        title: "Java",
        percentage: "60",
      },
      {
        title: "C++",
        percentage: "65",
      },
      {
        title: "c#",
        percentage: "45",
      },
    ],
  },
  {
    skillType: "Frontend development",
    skills: [
      {
        title: "ReactJS",
        percentage: "80",
      },
      {
        title: "Angular",
        percentage: "70",
      },
      {
        title: "VueJs",
        percentage: "50",
      },
    ],
  },
  {
    skillType: "Mobile Development",
    skills: [
      {
        title: "Flutter",
        percentage: "25",
      },
    ],
  },

  {
    skillType: "Backend development",
    skills: [
      {
        title: "NodeJS",
        percentage: "75",
      },
      {
        title: ".Net Framework",
        percentage: "55",
      },
      {
        title: "PHP(Symphony)",
        percentage: "30",
      },
      {
        title: "SpringBoot",
        percentage: "30",
      },
    ],
  },

  {
    skillType: "Databases",
    skills: [
      {
        title: "DynamoDB (AWS)",
        percentage: "70",
      },
      {
        title: "MongoDB",
        percentage: "60",
      },
      {
        title: "MySQL",
        percentage: "55",
      },
      {
        title: "PostgreSQL",
        percentage: "55",
      },
      {
        title: "ElasticSearch",
        percentage: "50",
      },
      {
        title: "FireBase",
        percentage: "45",
      },
      {
        title: "Neo4j",
        percentage: "25",
      },
    ],
  },

  {
    skillType: "Web scraping",
    skills: [
      {
        title: "Selenium",
        percentage: "50",
      },
      {
        title: "Beautiful Soup",
        percentage: "40",
      },
    ],
  },

  {
    skillType: "Project Management",
    skills: [
      {
        title: "Agile(Scrum)",
        percentage: "45",
      },
    ],
  },
  {
    skillType: "Conception",
    skills: [
      {
        title: "UML-Merise",
        percentage: "60",
      },
      {
        title: " Design Patterns",
        percentage: "55",
      },
    ],
  },
];
