import React from 'react'

import { certificates } from '../../API/CertificatesData';

import './Certificates.css'
import Card from './Card';

const Certificates = () => {
    return (
        <>


            <div className="mi-skills-area mi-section mi-padding-top">
                <div className="container">

                    <div className="mi-certificate" >
                        <div className="row mt-30-reverse">
                            {certificates.map((certificate, index) => (
                                <div key={index} className="col-xl-4 col-lg-6   mi-competence-item" >

                                    <Card
                                        image={certificate.Image}
                                        title={certificate.title}
                                        paragraph={certificate.Paragraph}
                                        filesource={certificate.filesource}
                                    />
                                </div>
                                // <div className="wpo-project-item" key={index}>
                                //     <div className="wpo-project-img" style={{ height: 380 }}>
                                //         <img src={certificate.Image} alt="" />
                                //         <div className="left-border"></div>
                                //         <div className="right-border"></div>
                                //     </div>
                                //     <div className="wpo-project-text">
                                //         <h2>{certificate.title}</h2>
                                //         <span>{certificate.paragraph}</span>
                                //     </div>
                                // </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default Certificates