import React, { useEffect, useState } from "react";
import "./PortfolioDetails.css";
import { Link, useParams } from "react-router-dom";
import { portfolios } from "../Portfolios/API/PortfolioData";
import SliderImage from "./SliderImage/SliderImage";
import {
  FaRegArrowAltCircleRight,
  FaRegArrowAltCircleLeft,
} from "react-icons/fa";

import { FiExternalLink } from "react-icons/fi";

const PortfolioDetails = () => {
  const { portfolioId } = useParams();
  const [project, setproject] = useState({});
  const [Nextindex, setNextindex] = useState(-1);
  const [Previousindex, setPreviousindex] = useState(-1);

  useEffect(() => {
    let project = portfolios.find(
      (project) => project.id.toString() === portfolioId
    );
    setproject(project);

    let nextindex =
      portfolios.findIndex(
        (project) => project.id === Number(portfolioId) - 1
      ) + 1;
    setNextindex(nextindex);
    let previousindex =
      portfolios.findIndex(
        (project) => project.id === Number(portfolioId) + 1
      ) + 1;
    setPreviousindex(previousindex);
  }, [project, portfolioId]);

  return (
    <>
      <div class="pb-3">
        <h1 class="title title--h1 Portfolio_Title  ">
          <Link className="Portfolio_Title_a" to={`/portfolios`}>
            {" "}
            ❮ Portfolios{" "}
          </Link>
        </h1>
        <h1 class="title title--h1 first-title title__separate">
          {" "}
          {project.title}
        </h1>

        <SliderImage images={project.images} url={project.URL} />

        {project.URL && (
          <a
            href={project.URL}
            target="_blank"
            rel="noreferrer"
            className="project_btn "
          >
            Visit project
            <FiExternalLink />
          </a>
        )}

        {project.technologies && (
          <div className="row technologies">
            <div className="tech-div">
              <h4>Used technologies: </h4>
              <div className="tech-img">
                {project.technologies.map((tech) => (
                  <div key={tech.id} className="tech-img-div">
                    <img alt="" src={tech.pic} />
                    <h5> {tech.name} </h5>
                  </div>
                ))}
              </div>

              {/* <div class="dlab-separator bg-primary m-b0"></div> */}
            </div>
          </div>
        )}

        <div className="box-inner box-inner--rounded project_description">
          <p>{project.description1}</p>
          <p>{project.description2}</p>
          <p>{project.description3}</p>
          <p>{project.description4}</p>
          <p>{project.description5}</p>
          <p>{project.description6}</p>
          <blockquote>
            <p>{project.description7}</p>
          </blockquote>
          <p>{project.description8}</p>
        </div>
        <div className="NextPreviousProject">
          {Previousindex !== 0 ? (
            <h6>
              <Link to={`/portfolios/details/${Previousindex}`}>
                <FaRegArrowAltCircleLeft className="NPicon" />
                Previous Project
              </Link>
            </h6>
          ) : (
            <h5> </h5>
          )}
          {Nextindex !== 0 ? (
            <h6>
              <Link to={`/portfolios/details/${Nextindex}`}>
                Next Project
                <FaRegArrowAltCircleRight className="NPicon" />
              </Link>
            </h6>
          ) : (
            <h5> </h5>
          )}
        </div>
      </div>
    </>
  );
};

export default PortfolioDetails;
